import * as Yup from "yup";

export const WithdrawSchema = Yup.object({
  withdrawAddress: Yup.string().required("This field is required."),
  assetMode: Yup.string().required("This field is required."),
  amount: Yup.number()
    .positive("Amount must be a positive number")
    .min(10, "Amount must be at least 10")
    .required("Amount is required"),
});
