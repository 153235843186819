import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import LogoutUser from "../Redux/actions/logoutAction";
import { LOGOUT_SUCCESS, RESET_ERROR } from "../Redux/states/loginState";
import axios from "axios";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [useData, setUserData] = useState({});
  const { LoginReducer } = useSelector((res) => res);

  const handleLogOut = () => {
    Swal.fire({
      text: "Are you sure you won't be Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(LogoutUser());
        localStorage.clear();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        alert("Cancelled");
      }
    });
  };
  const handleGoBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/", { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  const handleCheckisTokenVerify = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${LoginReducer.token}`;
      const resp = await axios.get(`/api/v1/user-authVerification`);
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error("session time out please login again");
        dispatch({ type: LOGOUT_SUCCESS });
        localStorage.clear();
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    if (LoginReducer?.isLogged && LoginReducer?.token) {
      setUserData(LoginReducer?.userdata);
      handleCheckisTokenVerify();
    } else {
      if (LoginReducer?.message !== "" && !LoginReducer.error) {
        dispatch({ type: RESET_ERROR });
        toast.success(LoginReducer?.message);
        navigate("/");
      }
    }
  }, [LoginReducer]);

  return (
    <>
      <nav className="navbar navbar-expand-lg d-md-block d-lg-block d-none">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img
              src="../HyperXalphalogo 1.png"
              className="img-fluid"
              alt="not"
            />
          </Link>
          <button
            className="navbar-toggler border-0 shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="black"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item cus-navitem">
                <NavLink to="/" className="nav-link " aria-current="page">
                  Market
                </NavLink>
              </li>
              <li to="/" className="nav-item cus-navitem">
                <NavLink to="/" className="nav-link">
                  About Us
                </NavLink>
              </li>
              {/* <li to="/" className="nav-item cus-navitem">
              <NavLink to="/" className="nav-link">
                Blog
              </NavLink>
            </li>
       
            <li to="/" className="nav-item cus-navitem">
              <NavLink to="/" className="nav-link">
                Coin Details
              </NavLink>
            </li>
            <li to="/" className="nav-item cus-navitem">
              <NavLink to="/" className="nav-link">
                Api
              </NavLink>
            </li>
            <li to="/" className="nav-item cus-navitem">
              <NavLink to="/" className="nav-link">
                Support
              </NavLink>
            </li> */}
            </ul>
            <div className="d-flex">
              {LoginReducer?.isLogged ? (
                <>
                  <div className="dropdown">
                    <Link
                      className="btn  cus-log-btn dropdown-toggle shadow-none"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa-solid fa-gear"></i> Setting
                    </Link>
                    <ul className="dropdown-menu pt-0 ">
                      <li className="card-header p-0 cus-header-drop disabled">
                        <p className="dropdown-item user-icon  py-2 mb-0 ">
                          <i class="fa-solid fa-user mx-1"></i> {useData.email}
                        </p>
                      </li>
                      {/* <li>
                        <Link className="dropdown-item mt-2" to="/user">
                          {" "}
                          <i class="fa-regular fa-user mx-1"></i> Profile
                        </Link>
                      </li> */}
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      {/* <li>
                      <Link className="dropdown-item mt-2">
                        {" "}
                        <i class="fa-solid fa-lock mx-1"></i> Account & Security
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li> */}
                      <li>
                        <Link className="dropdown-item" to="/referrals">
                          {" "}
                          <i class="fa-regular fa-share-from-square mx-1"></i>{" "}
                          Referral and Earn
                        </Link>
                      </li>
                      {/* <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/team">
                          {" "}
                          <i class="fa-solid fa-users-line mx-1"></i> Team
                        </Link>
                      </li> */}
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/kyc">
                          {" "}
                          <i class="fa-solid fa-id-card mx-1"></i> Kyc
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li>
                        <Link className="dropdown-item" to="/trade-history">
                          {" "}
                          <i class="fa-solid fa-money-bill-trend-up mx-1"></i>{" "}
                          Trade History
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/news">
                          {" "}
                          <i class="fa-solid fa-newspaper"></i> News
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          to="/withdrawal-pending"
                        >
                          {" "}
                          <i class="fa-solid fa-signs-post"></i> Withdrawal
                          Status
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li>
                        <Link className="dropdown-item" to="/history">
                          {" "}
                          <i class="fa-solid fa-signs-post"></i> History
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(e) => handleLogOut(e)}
                        >
                          <i class="fa-solid fa-right-from-bracket mx-1"></i>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="user-profile-h">
                    <div className="user-profile mx-1">
                      <img
                        src="https://img.freepik.com/premium-vector/business-global-economy_24877-41082.jpg"
                        alt="user"
                      />
                    </div>
                    {useData?.firstName}{" "}
                  </div>

                  <div className="massage-box">
                    <i class="fa-regular fa-message"></i>
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="btn cus-log-btn"
                    onClick={() => navigate("/login")}
                    data-bs-dismiss="offcanvas"
                  >
                    Login
                  </button>
                  <button
                    className="btn cus-log-btn"
                    onClick={() => navigate("/register")}
                    data-bs-dismiss="offcanvas"
                  >
                    Register
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* //  phone sidebar */}
      <nav class="navbar navbar-light bg-light  d-md-none d-lg-none d-block">
        <div class="container-fluid">
          <button
            className="btn shadow-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#414242"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </button>
          {/* <button
            className="navbar-toggler border-0 shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#414242"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </button> */}
          {/* <span class="navbar-brand mb-0 h1">HRX</span> */}
          <div className="massage-box ms-auto">
            <i class="fa-regular fa-message"></i>
          </div>
          <span className="backbtn" onClick={handleGoBack}>
            <i class="fa-solid fa-arrow-left"></i>
          </span>
        </div>
      </nav>

      {/* offcanwas- sidebar */}

      <div
        className="offcanvas offcanvas-start cus-offcanvas"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            <div className="navbar-brand cus-brand-sidebar">
              <img src="../HyperXalphalogo 1.png" alt="not" />
            </div>
          </h5>
          <button
            type="button"
            className="btn-close text-reset shadow-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="user-box">
          <img
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80"
            alt=""
          />
          {LoginReducer?.isLogged && <p>{useData && useData.email}</p>}
        </div>

        <div className="offcanvas-body">
          <div className="menu-box">
            {!LoginReducer?.isLogged && (
              <div className="sidebard-btnbox">
                <button
                  className="btn cus-btn"
                  data-bs-dismiss="offcanvas"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <button
                  className="btn cus-btn"
                  data-bs-dismiss="offcanvas"
                  onClick={() => navigate("/register")}
                >
                  Ragister
                </button>
              </div>
            )}

            <ul>
              <NavLink to={"/"} data-bs-dismiss="offcanvas">
                <li className="nav-link side-nav">
                  {" "}
                  <i class="fa-solid fa-house mx-1"></i> Home
                </li>
              </NavLink>
              {LoginReducer?.isLogged && (
                <>
                  {/* <NavLink
                    to={"/user"}
                    className="nav-link side-nav"
                    data-bs-dismiss="offcanvas"
                  >
                    {" "}
                    <li>
                      {" "}
                      <i class="fa-regular fa-user mx-1"></i> Profile
                    </li>
                  </NavLink> */}
                  <NavLink to={"/referrals"} data-bs-dismiss="offcanvas">
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-regular fa-share-from-square mx-1"></i>
                      Referral and Earn
                    </li>
                  </NavLink>
                  {/* <NavLink to={"/team"} data-bs-dismiss="offcanvas">
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-users-line mx-1"></i> Team
                    </li>
                  </NavLink> */}
                  <NavLink to={"/kyc"} data-bs-dismiss="offcanvas">
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-id-card mx-1"></i> Kyc
                    </li>
                  </NavLink>
                  <NavLink to={"/trade-history"} data-bs-dismiss="offcanvas">
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-money-bill-trend-up mx-1"></i>Trade
                      History
                    </li>
                  </NavLink>
                  <NavLink to={"/news"} data-bs-dismiss="offcanvas">
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-newspaper mx-1"></i>
                      News
                    </li>
                  </NavLink>

                  <NavLink
                    to={"/withdrawal-pending"}
                    data-bs-dismiss="offcanvas"
                  >
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-newspaper mx-1"></i>
                      Withdrawal Status
                    </li>
                  </NavLink>
                  <NavLink to={"/history"} data-bs-dismiss="offcanvas">
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-signs-post mx-1"></i>
                      History
                    </li>
                  </NavLink>
                  <Link
                    data-bs-dismiss="offcanvas"
                    onClick={(e) => handleLogOut(e)}
                  >
                    <li className="nav-link side-nav">
                      {" "}
                      <i class="fa-solid fa-right-from-bracket mx-1"></i>Logout
                    </li>
                  </Link>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
