import React from "react";
import Layout from "../../sections/Layout/Content";
import "./styles.css";
const Content = () => {
  return (
    <Layout>
      <div className="container my-3">
        <div className="row">
          <div className="col-12">
            <div className="user-header">
              <h3>Hi, Majh******aikh</h3>
            </div>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="username-l">
                      <p>Your membership level is :</p>
                      <span>VIP4</span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 text-end">
                    <div className="userbox-btn">
                      <button className="btn cus-log-btn ">
                        Mission & Rewards{" "}
                      </button>
                      <button className="btn cus-log-btn">
                        {" "}
                        Rules & Benefits
                      </button>
                      <button className="btn cus-log-btn">Signed in</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="user-card-body">
                  <p className="mb-0">Accumulated rewards:</p>
                  <span> 24.9 </span>
                  <p> USDT</p>
                </div>

                <div className="userlistbox">
                  <p>
                    Daily check-in can get 0USDT reward, accumulated check-in
                    reaches the specified number of days to get additional
                    reward{" "}
                  </p>
                  <ol>
                    <li>Check-in rules: manual check-in, only once a day.</li>
                    <li>
                      Time rules: from 0:00 a.m. to 0:00 a.m. the next day is
                      the check-in time of a natural day.
                    </li>
                    <li>
                      Reward rules: the higher the level, the greater the amount
                      of daily check-in
                    </li>
                    <li>
                      Receiving rules: after signing in to receive, the account
                      balance will be reached automatically
                    </li>
                    <li>
                      Promotion rules: If you meet the requirements of each VIP
                      level, you will be promoted to the corresponding VIP level
                    </li>
                    <li>
                      Reward amount: the higher the level, the higher the reward
                      amount, the highest level of 5000USDT
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 my-4">
            <div className="card">
              <div className="card-body">
                <p>Identity Verification</p>
                <p style={{ color: "gray" }}>
                  You are suggested to complete identity verification in order
                  to ensure financial security, raise withdraw credit and enjoy
                  trading privileges.
                </p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue
                    id="flexCheckCheckedDisabled"
                    defaultChecked
                    checked
                  />
                  <label className="form-check-label" htmlFor="">
                    Completed
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3">
            <div className="card link-card p-3">
              <a href="">Assets</a>
              <a href="">Financial account</a>
              <a href="">Spot Orders</a>
              <a href="">Futures Orders</a>
              <a href="">Notice</a>
            </div>
          </div>

          <div className="col-12 col-md-9 mb-2">
            <div className="card link-card p-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-2">
                    <p>Account Security</p>
                  </div>
                  <div className="col-6">
                    <p>Account</p>
                  </div>
                  <div className="col-6 text-end">
                    <p>xyz454320@gmail.com</p>
                  </div>
                </div>
              </div>
              <a href="">Assets</a>
              <a href="">Financial account</a>
              <a href="">Spot Orders</a>
              <a href="">Futures Orders</a>
              <a href="">Notice</a>
            </div>
          </div>
           <div className="col-12 my-2">
              <div className="card p-2">
                 <p>Referral & Earn</p>
             

               <div className="row">
                  <div className="col-12 col-md-3">
                     <div className="card ref-box-icon">
                     <i class="fa-solid fa-hand-holding-dollar"></i>
                        <p>Referral Stats</p>
                     </div>
                  </div>
                  <div className="col-12 col-md-3">
                     <div className="card ref-box-icon">
                     <i class="fa-solid fa-user-group"></i>
                        <p>1st level friends</p>
                     </div>
                  </div>
                  <div className="col-12 col-md-3">
                     <div className="card ref-box-icon">
                     <i class="fa-solid fa-people-group"></i>
                        <p>2nd level friends</p>
                     </div>
                  </div>
                  <div className="col-12 col-md-3">
                     <div className="card ref-box-icon">
                     <i class="fa-solid fa-people-roof"></i>
                        <p>3rd level friends</p>
                     </div>
                  </div>
               </div>
               </div>
           </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
