import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const { LoginReducer } = useSelector((res) => res);
  const [referralcode, setReferralCode] = useState("");
  const [levelData, setLevelData] = useState({});
  const [singleLevelData, setSingleLevelData] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copytext, setCopyText] = useState("copy");
  const [copyLink, setCopyLink] = useState("copy");
  const [totalActiveUsers, setTotalActiveUsers] = useState("")
  const handleCopy = async () => {
    setCopyText("Copied!");
    let text = referralcode && referralcode;

    // navigator.clipboard.writeText(text);
    // if ('clipboard' in navigator) {
    //   await navigator.clipboard.writeText(text);
    // } else {
    document.execCommand("copy", true, text);
    // }
  };
  const handleCopyLink = () => {
    setCopyLink("Copied!");
    let text1 = `https://www.hyperxalpha.com/register/id?${referralcode && referralcode
      }`;
    text1.trim();
    document.execCommand("copy", true, text1);
    // navigator.clipboard.writeText(text1);
  };
  const userLevelDownline = async () => {
    setLoading(true);
    try {
      const resp = await axios("/api/v1/user-level-downline");
      setLevelData(resp.data.data);
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const filterLevel = async (level) => {
    setLoading(true);
    try {
      const resp = await axios(`/api/v1/user-downlineByFilter?level=${level}`);
      console.log("resp.data.data", resp.data.data);
      setSingleLevelData(resp.data.data.Level);
      setTotalActiveUsers(resp.data.data.totalActiveUsers)
   
      setUsers(resp.data.data.Level.users);
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  setTimeout(() => {
    setCopyText("copy");
    setCopyLink("copy");
  }, 2000);
  useEffect(() => {
    userLevelDownline();
  }, []);

  useEffect(() => {
    if (LoginReducer.isLogged) {
      const { refferalCode } = LoginReducer?.userdata;
      setReferralCode(refferalCode);
    }
  }, [LoginReducer]);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="ref-heading-box">
              <h4>Referral Code & Link</h4>
            </div>
            <div className="card  ref-card p-4">
              <div className="row">
                <div className="col-12 col-md-6">
                  <p className="mb-1">Referral code</p>
                  <div className="copy-box">
                    <div className="input-group mb-3">
                      {/* <input type="text" className="form-control"  value="uee8f " /> */}
                      <p className="mx-2">{referralcode && referralcode}</p>
                      <div className="input-group-prepend">
                        <CopyToClipboard
                          text={referralcode && referralcode}
                          onCopy={handleCopy}
                        >
                          <span
                            className="input-group-text copy-btn"
                            id="basic-addon1"
                          >
                            <i className="fa-regular fa-copy mx-1"></i>{" "}
                            {copytext}
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <p className="mb-1">Referral link</p>
                  <div className="copy-box">
                    <div>
                      <div className="input-group mb-3">
                        {/* <input type="text" className="form-control"  value="uee8f " /> */}
                        <p className="mx-2">
                          https://www.hyperxalpha.com/register/id?
                          {referralcode && referralcode}
                        </p>
                        <div className="input-group-prepend">
                          <CopyToClipboard
                            text={`https://www.hyperxalpha.com/register/id?${referralcode && referralcode
                              }`}
                            onCopy={handleCopyLink}
                          >
                            <span
                              className="input-group-text copy-btn"
                              id="basic-addon1"
                            >
                              <i className="fa-regular fa-copy mx-1"></i>{" "}
                              {copyLink}
                            </span>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 my-2">
            <div className="card ">
              <div className="row">
                <div className="col-12">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item tab-cus-nav" role="presentation">
                      <button
                        className="nav-link active tab-link"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Referral Stats
                      </button>
                    </li>
                    <li className="nav-item tab-cus-nav" role="presentation">
                      <button
                        className="nav-link tab-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => filterLevel(1)}
                      >
                        1st level friends
                      </button>
                    </li>
                    <li className="nav-item tab-cus-nav" role="presentation">
                      <button
                        className="nav-link tab-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => filterLevel(2)}
                      >
                        2nd level friends
                      </button>
                    </li>

                    {/* 4 */}
                    <li className="nav-item tab-cus-nav" role="presentation">
                      <button
                        className="nav-link tab-link"
                        id="four-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => filterLevel(3)}
                      >
                        3rd level friends
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="col-12 my-3">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade p-2 show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{levelData?.totalCount}</p>
                            <p># friends</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{levelData?.totalActiveUsers}</p>
                            <p># activated</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{levelData?.todaysCommission}</p>
                            <p>Today's Commissions</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{levelData?.totalCommission}</p>
                            <p>Total Commissions</p>
                          </div>
                        </div>
                      </div>

                      <div className="row my-5">
                        <div className="col-12">
                          <div className="table-box-ref">
                            <table className="table">
                              <thead className="t-head-ref">
                                <tr>
                                  <th>Level</th>
                                  <th># friends</th>
                                  <th>Today's Commissions</th>
                                  <th>Total Commissions</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>#1</td>
                                  <td>{levelData?.Level1?.count}</td>
                                  <td>
                                    {levelData?.Level1?.todaysL1Commission}
                                  </td>
                                  <td>
                                    {levelData?.Level1?.totalL1Commission}
                                  </td>
                                </tr>
                                <tr>
                                  <td>#2</td>
                                  <td>{levelData?.Level2?.count}</td>
                                  <td>
                                    {levelData?.Level2?.todaysL2Commission}
                                  </td>
                                  <td>
                                    {levelData?.Level2?.totalL2Commission}
                                  </td>
                                </tr>
                                <tr>
                                  <td>#3</td>
                                  <td>{levelData?.Level3?.count}</td>
                                  <td>
                                    {levelData?.Level3?.todaysL3Commission}
                                  </td>
                                  <td>
                                    {levelData?.Level3?.totalL3Commission}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{singleLevelData?.count}</p>
                            <p># friends</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{totalActiveUsers || 0}</p>
                            <p># activated</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{singleLevelData?.todaysCommission || 0}</p>
                            <p>Today's Commissions</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>{singleLevelData?.totalCommission || 0}</p>
                            <p>Total Commissions</p>
                          </div>
                        </div>
                      </div>

                      <div className="row my-5">
                        <div className="col-12">
                          <div className="table-box-ref">
                            <table className="table">
                              <thead className="t-head-ref">
                                <tr>
                                  <th>Account</th>
                                  <th>Time Registered</th>
                                </tr>
                              </thead>
                              <tbody>
                                {users?.map((data, index) => {
                                  return (
                                    <tr
                                      className=""
                                      key={index}
                                    // style={{ backgroundColor: "lightblue" }}
                                    >
                                      <td>
                                        <div
                                          className=""
                                          style={{ position: "relative" }}
                                        >
                                          <div>
                                            {data.firstName +
                                              " " +
                                              data.lastName}
                                          </div>
                                          <div>{data.email}</div>
                                          {data.isActive ? (
                                            <div
                                              style={{
                                                color: "green",
                                                position: "absolute",
                                                top: "0",
                                                right: "10%",
                                                width: "70px",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              active
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                color: "red",
                                                position: "absolute",
                                                top: "0",
                                                right: "10%",
                                                width: "70px",
                                                paddingLeft: "4px",
                                              }}
                                            >
                                              inactive
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td>0</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="contact"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p># friends</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p># activated</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p>Today's Commissions</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p>Total Commissions</p>
                          </div>
                        </div>
                      </div>

                      <div className="row my-5">
                        <div className="col-12">
                          <div className="table-box-ref">
                            <table className="table">
                              <thead className="t-head-ref">
                                <tr>
                                  <th>Level</th>
                                  <th># friends</th>
                                  <th>Today's Commissions</th>
                                  <th>Total Commissions</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="four"
                      role="tabpanel"
                      aria-labelledby="four-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p># friends</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p># activated</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p>Today's Commissions</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="card raf-within-card">
                            <p>00</p>
                            <p>Total Commissions</p>
                          </div>
                        </div>
                      </div>

                      <div className="row my-5">
                        <div className="col-12">
                          <div className="table-box-ref">
                            <table className="table">
                              <thead className="t-head-ref">
                                <tr>
                                  <th>Level</th>
                                  <th># friends</th>
                                  <th>Today's Commissions</th>
                                  <th>Total Commissions</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>#1</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>0</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
