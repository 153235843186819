import {
  LOGOUT_REQUEST,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS,
} from "../states/loginState";

import axios from "axios";
const LogoutUser = (data) => {
  // const router = useRouter();
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    try {
      const response = await axios({
        method: "POST",
        url: "/api/v1/userLogOut",
      });
      if (response?.status === 200) {
        if (response?.data) {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: response.data.message,
          });
        } else {
          dispatch({
            type: LOGOUT_FAILED,
            payload: response.data.message,
          });
        }
        // router.push("/user/p2p");
      } else {
        dispatch({
          type: LOGOUT_FAILED,
          payload: response.data.message,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        dispatch({
          type: LOGOUT_FAILED,
          payload: err_400,
        });
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        dispatch({
          type: LOGOUT_FAILED,
          payload: err_401,
        });
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        dispatch({
          type: LOGOUT_FAILED,
          payload: err_500,
        });
      } else {
        dispatch({
          type: LOGOUT_FAILED,
          payload: "something went wrong",
        });
      }
    }
  };
};
export default LogoutUser;
