import React, { useState } from "react";
import Layout from "../../sections/Layout/Content";
import "./styles.css";
import { useFormik } from "formik";
import { DepositSchema } from "./DepositSchema";
import { FidgetSpinner } from "react-loader-spinner";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import axios from "axios";
import { USDTAbi, USDTAddress } from "../../abi/Content";
import Loading from "../LoaderPageSection/Loading";

import AdminDeposit from "./AdminDeposit";
const Content = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    walletAddress: "",
    assetMode: "",
    amount: "",
  };
  // change network

  const networkId = 137; //mainnet
  // const networkId = 80001; //testnet

  /*Switch to mainnet*/
  const networkData = [
    {
      chainId: ethers.utils.hexlify(networkId),
      rpcUrls: ["https://polygon-rpc.com"],
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC", // 2-6 characters long
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
  ];

  // /*Switch to Testnet*/
  // const networkData = [
  //   {
  //     chainId: ethers.utils.hexlify(networkId),
  //     rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
  //     chainName: "Matic Testnet",
  //     nativeCurrency: {
  //       name: "MATIC",
  //       symbol: "MATIC", // 2-6 characters long
  //       decimals: 18,
  //     },
  //     blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  //   },
  // ];

  const switchNetworks = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexlify(networkId) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: networkData,
          });
        } catch (addError) {}
      }
    }
  };

  const handleDepositApi = async (values, receipttxn) => {
    try {
      const depositData = {
        asset: "USDT",
        accountId: values.walletAddress,
        usdtAmount: values.amount,
        transactionHash: receipttxn.transactionHash,
      };
      localStorage.setItem("DepositData", JSON.stringify(depositData));
      const resp = await axios.post(`/api/v1/deposit-assets`, depositData);
      if (resp.status === 200) {
        if (resp.data.status === true) {
          toast.success(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (resp.data.status === false) {
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleConnectWallet = async () => {
    setIsLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }

      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      if (network.chainId !== networkId) {
        await switchNetworks();
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      values.walletAddress = address;
      localStorage.setItem("address", address);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  const handleDeposit = async (values) => {
    setLoading(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const USDTDeposit = new ethers.Contract(USDTAddress, USDTAbi, signer);
      const txn = await USDTDeposit.transfer(
        "0xe3c4fd1217318bfcca1964aadff10ffa384800f3",
        values.amount * 10 ** 6
      );
      const receipttxn = await txn.wait();
      if (receipttxn.status !== 1) {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        handleDepositApi(values, receipttxn);
      }
    } catch (error) {
      console.log("error", error);
      const obj = JSON.stringify(error);
      const obj2 = JSON.parse(obj);
      const obj3 = obj2.reason;
      toast.error(obj3, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
  };
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: DepositSchema,
      onSubmit: async (values) => {
        await handleDeposit(values);
      },
    });

  return (
    <>
      <Layout>
        {loading && <Loading loading={loading} />}
        <section className="depositbg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav>
                  <div
                    class="nav nav-tabs cusnav-tab"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      class="btn cusnav-link active cus-btn"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Crypto wallet
                    </button>
                    <button
                      class="btn cusnav-link cus-btn"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Deposit
                    </button>
                  </div>
                </nav>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="deposit-box">
                          <h3>Deposit</h3>
                          <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="mb-1"
                              >
                                Wallet Address
                                <span className="text-danger mx-1 fw-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control cus-formcontrol"
                                placeholder="Wallet Address"
                                name="walletAddress"
                                value={values.walletAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled
                              />
                              {errors.walletAddress && touched.walletAddress ? (
                                <div className="form-text text-danger">
                                  {errors.walletAddress}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="mb-1"
                              >
                                Asset Mode
                                <span className="text-danger mx-1 fw-bold">
                                  *
                                </span>
                              </label>
                              <select
                                className="form-control cus-formcontrol"
                                name="assetMode"
                                value={values.assetMode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="" disabled>
                                  ----Select Asset Mode----
                                </option>
                                <option value="USDT">USDT</option>
                              </select>
                              {errors.assetMode && touched.assetMode ? (
                                <div className="form-text text-danger">
                                  {errors.assetMode}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <label
                                htmlFor="exampleInputPassword1"
                                className="mb-1"
                              >
                                Amount
                                <span className="text-danger mx-1 fw-bold">
                                  *
                                </span>
                              </label>
                              <input
                                type="number"
                                className="form-control cus-formcontrol"
                                placeholder="Enter USDT Amount"
                                name="amount"
                                value={values.amount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.amount && touched.amount ? (
                                <div className="form-text text-danger">
                                  {errors.amount}
                                </div>
                              ) : null}
                            </div>
                            {values?.walletAddress !== "" ? (
                              <button
                                type="submit"
                                className="btn form-btn w-100"
                                disabled={isLoading}
                              >
                                Deposit{" "}
                                {isLoading && (
                                  <FidgetSpinner
                                    visible={true}
                                    height="24"
                                    width="35"
                                    ariaLabel="dna-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="dna-wrapper"
                                    ballColors={[
                                      "#ff0000",
                                      "#00ff00",
                                      "#0000ff",
                                    ]}
                                    backgroundColor="#000"
                                  />
                                )}
                              </button>
                            ) : (
                              <button
                                className="btn form-btn w-100"
                                disabled={isLoading}
                                onClick={() => handleConnectWallet()}
                              >
                                Connect to Wallet{" "}
                                {isLoading && (
                                  <FidgetSpinner
                                    visible={true}
                                    height="24"
                                    width="35"
                                    ariaLabel="dna-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="dna-wrapper"
                                    ballColors={[
                                      "#ff0000",
                                      "#00ff00",
                                      "#0000ff",
                                    ]}
                                    backgroundColor="#000"
                                  />
                                )}
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <AdminDeposit />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Content;
