import React from "react";
import Header from "./Header";
import Foooter from "./Foooter";
import "./styles.css";
const Content = ({ children }) => {
  return (
    <>
      <Header />
      <div className="main-container">{children}</div>
      {/* <Foooter /> */}
    </>
  );
};

export default Content;
