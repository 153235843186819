import React from "react";
import "./styles.css";
import Layout from "../../sections/Layout/Content";
const Content = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="news-heading-box">
              <span>News </span>
              <i className="fa-regular fa-bell" />
            </div>
            <hr className="mx-auto my-1" style={{ width: "140px" }} />
          </div>
        </div>
        <div className="row">
          <div className="col-12  col-md-8 mx-auto">
            <div className="news-main-box">
              <div className="my-3"></div>
              <div className="">
                <div className="shadow border-start border-primary border-3 d-flex align-items-center p-2 my-3">
                  <div className="mx-3 ">
                    <div
                      className="img-fluid rounded-circle"
                      style={{
                        backgroundColor: "#032c62",
                        width: "50px",
                        height: "50px",
                      }}
                    ></div>
                  </div>
                  <div className="mt-2">
                    <div className="">
                      <h5>Lorem ipsum dolor sit amet </h5>
                    </div>
                    <div>
                      adipisicing elit. Nemo nostrum repellendus tempore
                      inventore alias,
                    </div>
                    <div className="mt-1">
                      <p className="text-secondary">
                        <small>10 mins ago</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="shadow border-start border-primary border-3 d-flex align-items-center p-2 my-3">
                  <div className="mx-3 ">
                    <div
                      className="img-fluid rounded-circle"
                      style={{
                        backgroundColor: "#032c62",
                        width: "50px",
                        height: "50px",
                      }}
                    ></div>
                  </div>
                  <div className="mt-2">
                    <div className="">
                      <h5>Lorem ipsum dolor sit amet </h5>
                    </div>
                    <div>
                      adipisicing elit. Nemo nostrum repellendus tempore
                      inventore alias,
                    </div>
                    <div className="mt-1">
                      <p className="text-secondary">
                        <small>10 mins ago</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" shadow border-start border-primary border-3  d-flex align-items-center p-2 my-3">
                  <div className="mx-3 ">
                    <div
                      className="img-fluid rounded-circle"
                      style={{
                        backgroundColor: "#032c62",
                        width: "50px",
                        height: "50px",
                      }}
                    ></div>
                  </div>
                  <div className="mt-2">
                    <div className="">
                      <h5>Lorem ipsum dolor sit amet </h5>
                    </div>
                    <div>
                      adipisicing elit. Nemo nostrum repellendus tempore
                      inventore alias,
                    </div>
                    <div className="mt-1">
                      <p className="text-secondary">
                        <small>10 mins ago</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
