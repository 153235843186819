import React from "react";
import Layout from "../Layout/Content";
import "./styles.css";
import { useNavigate } from "react-router-dom";
const Content = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div id="main" className="container-fluid">
        <h1 align="center" className="error-head">
          Something went wrong.
        </h1>
        <div className="row">
          <div className="col-md-6 col-12">
            <img
              src="https://s-media-cache-ak0.pinimg.com/564x/6c/9d/bd/6c9dbd585b7886bf27adfab8e75f3e95.jpg"
              alt="sad pokemon"
              className="displayed"
            />
          </div>
          <div className=" col-md-6 col-lg-6">
            <h1 id="error">404</h1>
            <br />
            <h3>Sorry, we can't find the page.</h3>
            <div>
              <button
                className="btn btn-back my-3"
                onClick={() => navigate("/")}
              >
                Go Back to HomePage
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
