import React, { useState, useEffect } from "react";
import Layout from "../../sections/Layout/Content";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { RegisterSchema } from "./RegisterSchema";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Content = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isShow, setisShow] = useState(false);
  const [isOtpShow, setisOtpShow] = useState(false);
  const [otpVerify, setOtpVerify] = useState({});
  const [emailVerify, setEmailVerify] = useState({});
  const [otpExpiration, setOtpExpiration] = useState(180); // 2 minutes in seconds
  const [otpExpired, setOtpExpired] = useState(false);
  const [isReferral, setIsReferral] = useState("");
  const [showResend, setShowResend] = useState(false);

  const [loading, setLoading] = useState(false);
  const initialValues = {
    firstName: "",
    lastName: "",
    otp: "",
    email: "",
    password: "",
    confirmPassword: "",
    referral: "",
  };
  const handleRegister = async (values) => {
    try {
      const dataResponse = await axios({
        method: "POST",
        url: "/api/v1/userRegistration",
        data: {
          firstName: values?.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          cPassword: values.confirmPassword,
          sponserCode: isReferral ? isReferral : values.referral,
        },
      });
      const outputData = dataResponse?.data;
      if (outputData?.status == true) {
        toast.success(outputData?.message);
        navigate("/");
      } else {
        toast.error(outputData?.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const dataResponse = await axios({
        method: "POST",
        url: "/api/v1/verifyUserEmail",
        data: {
          email: values?.email,
        },
      });
      const dataoutPut = dataResponse?.data;
      if (dataoutPut?.status) {
        setisOtpShow(true);
        setEmailVerify({
          status: true,
          success: dataoutPut?.message,
        });
        localStorage.setItem("emailAddress", values.email);
      } else {
        setisOtpShow(false);
        setEmailVerify({
          status: false,
          error: dataoutPut?.message,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleResendOTP = async () => {
    console.log("email", values.email);
    const data = { email: values.email };
    setLoading(true);
    try {
      const resp = await axios.post(`/api/v1/resendOTP`, data);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // setisOtpShow(true)
          setOtpExpired(false);
          setOtpExpiration(180);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const handlecheckOtp = async () => {
    try {
      const otpapiResponse = await axios({
        method: "POST",
        url: "/api/v1/verifyOTP",
        data: {
          email: values?.email,
          otp: values?.otp,
        },
      });
      const outputOtpApi = otpapiResponse?.data;
      if (outputOtpApi?.status) {
        setOtpVerify({
          status: true,
          success: outputOtpApi?.message,
        });
        setisShow(true);
      } else {
        setOtpVerify({
          status: false,
          error: outputOtpApi?.message,
        });
        setisShow(false);
      }
      console.log("Otp Response", outputOtpApi);
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: RegisterSchema,
      onSubmit: async (values) => {
        await handleRegister(values);
      },
    });

  useEffect(() => {
    if (values?.otp?.toString().length == 6) {
      handlecheckOtp();
    }
  }, [values.otp]);

  // opt expired
  useEffect(() => {
    const TimeCount = async () => {
      var countdownInterval = "";
      if (isOtpShow) {
        countdownInterval = setInterval(() => {
          setOtpExpiration((prevExpiration) => prevExpiration - 1);
        }, 1000);
      }

      // Clear the interval when the component unmounts or when the OTP expires
      return () => {
        clearInterval(countdownInterval);
      };
    };
    TimeCount();
  }, [isOtpShow]);

  useEffect(() => {
    if (otpExpiration <= 0) {
      setOtpExpired(true);
      // You can add code here to handle the OTP expiration, e.g., show a message, disable a button, etc.
    }
    if (otpExpiration === 0) {
      setShowResend(true);
      // setisOtpShow(false)
    }
  }, [otpExpiration]);

  // Format the countdown time as "mm:ss"
  const formattedTime = `${String(Math.floor(otpExpiration / 60)).padStart(
    2,
    "0"
  )} : ${String(otpExpiration % 60).padStart(2, "0")}`;

  useEffect(() => {
    if (location?.search !== "") {
      var dataSplit = location?.search.split("/");
      dataSplit = dataSplit[0].split("?")[1];
      setIsReferral(dataSplit);
    } else {
      values.referral = "";
      setIsReferral("");
    }
  }, [location?.pathname]);
  return (
    <>
      <Layout>
        <section className="logbg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="logbox">
                  <h3>Register</h3>
                  {/* <form onSubmit={handleSubmit}> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group mb-3">
                        <label htmlFor="exampleInputEmail1" className="mb-1">
                          First Name
                        </label>
                        <span className="text-danger mx-1 fw-bold">*</span>
                        <input
                          type="text"
                          className="form-control cus-formcontrol"
                          placeholder="Enter First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.firstName && touched.firstName ? (
                          <div className="form-text text-danger">
                            {errors.firstName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group mb-3">
                        <label htmlFor="exampleInputEmail1" className="mb-1">
                          Last Name
                        </label>
                        <span className="text-danger mx-1 fw-bold">*</span>
                        <input
                          type="text"
                          className="form-control cus-formcontrol"
                          placeholder="Enter Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.lastName && touched.lastName ? (
                          <div className="form-text text-danger">
                            {errors.lastName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <label htmlFor="exampleInputEmail1 d-block" className="mb-1">
                    Email
                  </label>
                  <span className="text-danger mx-1 fw-bold">*</span>
                  <div className="mb-2">
                    <div className="input-group mb-1">
                      <input
                        type="email"
                        className="form-control cus-formcontrol"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showResend ? (
                        <button
                          className={`btn form-btn input-group-text ${
                            otpVerify.status === true && "d-none"
                          }`}
                          onClick={() => handleResendOTP()}
                          disabled={!otpExpired}
                        >
                          Resend OTP
                        </button>
                      ) : (
                        <button
                          className="btn form-btn input-group-text"
                          onClick={() => handleVerifyEmail()}
                          disabled={otpVerify.status || isOtpShow}
                        >
                          {otpVerify.status === true ? "Verified" : "Verify"}
                        </button>
                      )}
                    </div>
                    <div>
                      {errors.email && touched.email ? (
                        <div className="form-text text-danger">
                          {errors.email}
                        </div>
                      ) : null}
                      {!emailVerify.status ? (
                        <div className="form-text text-warning">
                          {emailVerify.error}
                        </div>
                      ) : (
                        <div className="form-text text-success">
                          {emailVerify.success}
                        </div>
                      )}
                    </div>
                  </div>
                  {isOtpShow && (
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control cus-formcontrol"
                        placeholder="Enter OTP"
                        name="otp"
                        value={values.otp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className={otpVerify.status === true && `d-none`}>
                        {otpExpired ? (
                          <p className="my-2">OTP has expired.</p>
                        ) : (
                          <p>OTP will expire in {formattedTime}</p>
                        )}
                      </div>

                      {errors.otp && touched.otp ? (
                        <div className="form-text text-danger">
                          {errors.otp}
                        </div>
                      ) : null}

                      {!otpVerify.status ? (
                        <div className="form-text text-warning">
                          {otpVerify.error}
                        </div>
                      ) : (
                        <div className="form-text text-success">
                          {otpVerify.success}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="form-group mb-3">
                    <label className="mb-1">Password</label>
                    <span className="text-danger mx-1 fw-bold">*</span>
                    <input
                      type="password"
                      className="form-control cus-formcontrol"
                      placeholder="Password (at least 6 characters)"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isShow}
                    />
                    {errors.password && touched.password ? (
                      <div className="form-text text-danger">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1">Confirm Password</label>
                    <span className="text-danger mx-1 fw-bold">*</span>
                    <input
                      type="password"
                      className="form-control cus-formcontrol"
                      placeholder="confirm Password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isShow}
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="form-text text-danger">
                        {errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1">Referral Code</label>
                    <span className="text-danger mx-1 fw-bold">*</span>
                    <input
                      type="text"
                      className="form-control cus-formcontrol"
                      placeholder="Referral Code"
                      name="referral"
                      value={isReferral ? isReferral : values.referral}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!isShow}
                    />
                    {errors.referral && touched.referral ? (
                      <div className="form-text text-danger">
                        {errors.referral}
                      </div>
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className="btn form-btn w-100"
                    onClick={() => handleSubmit()}
                  >
                    Next Step
                  </button>
                  {/* </form> */}

                  <div className="row my-3">
                    <div className="col-12">
                      <div className="linkbox text-center">
                        <NavLink to="/login">Login</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Content;
