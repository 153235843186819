import React, { useState } from "react";
import "./styles.css";
import Layout from "../Layout/Content";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const ForgotPasswordEmail = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleResetPassword = async () => {
    try {
      const dataResponse = await axios({
        method: "POST",
        url: "/api/v1/forget-password",
        data: {
          email: email,
        },
      });
      const outputData = dataResponse?.data;
      console.log("outputData", outputData);
      if (dataResponse?.status === 200) {
        if (outputData?.status === true) {
          toast.success(outputData?.message);
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  return (
    <div>
      <Layout>
        <section className="logbg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="logbox">
                  <h3>Forgot Password</h3>
                  <div className="form-group mb-3">
                    <label className="mb-1">Email</label>
                    <input
                      type="text"
                      className="form-control cus-formcontrol"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn form-btn w-100"
                    onClick={() => handleResetPassword()}
                  >
                    Next Step
                  </button>
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn form-btn w-50"
                      onClick={() => navigate("/login")}
                    >
                      Go Back to Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default ForgotPasswordEmail;
