import React, { useState, useEffect } from "react";
import "./styles.css";
import Layout from "../Layout/Content";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const navigate = useNavigate();
  const [adharcardName, setAdharcardName] = useState("");
  const [adharcardNo, setAdharcardNo] = useState("");
  const [panardName, setPancardName] = useState("");
  const [pancardNo, setPancardNo] = useState("");
  const [drivingLicenseName, setDrivingLicenseName] = useState("");
  const [drivingLicenNo, setDrivingLicenNo] = useState("");
  const [drivingLicenFrontfile, setDrivingLicenFrontfile] = useState("");
  const [drivingLicenBackfile, setDrivingLicenBackfile] = useState("");
  const [drivingLicenFrontUrl, setDrivingLicenFrontUrl] = useState("");
  const [drivingLicenBackUrl, setDrivingLicenBackUrl] = useState("");
  const [file, setFile] = useState("");
  const [adharbackfile, setAdharbackfile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageAdharBackUrl, setImageAdharBackUrl] = useState("");
  const [kycmode, setkycMode] = useState("aadhar");
  const [bannerStatus, setBannerStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleAdharcardKyc = async (e) => {
    if (adharcardName) {
      if (adharcardNo) {
        if (file && adharbackfile) {
          // const formData = new FormData();
          // formData.append("name", adharcardName);
          // formData.append("aadharNumber", adharcardNo);
          // formData.append("kycType", kycmode);
          // formData.append("aadharFront", file);
          // formData.append("aadharBack", adharbackfile);
          let formdata = {
            name: adharcardName,
            aadharNumber: adharcardNo,
            kycType: kycmode,
            aadharFront: file,
            aadharBack: adharbackfile,
          };
          setLoading(true);
          try {
            const resp = await axios.post(`/api/v1/user-kyc`, formdata);
            if (resp.status === 200) {
              if (resp?.data?.status === true) {
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/");
                console.log("kyc Update", resp);
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            if (error.response.status === 400) {
              var err_400 = error?.response?.data?.message;
              alert(err_400);
              // toast.error(err_400);
            } else if (error.response.status === 401) {
              const err_401 = error?.response?.data?.message;
              alert(err_401);
              // toast.error(err_401);
            } else if (error.response.status === 500) {
              const err_500 = error?.response?.data?.message;
              // toast.error(err_500);
              alert(err_500);
            } else {
              alert("error in kyc details");
              console.log("Error in kyc details", error);
              // toast.error("something went wrong");
            }
          }
          setLoading(false);
        } else {
          toast.error("Upload Images", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Enter Adharcard Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Enter Adharcard Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDrivingLicenseKyc = async (e) => {
    if (panardName) {
      if (pancardNo) {
        if (drivingLicenFrontfile && drivingLicenBackfile) {
          // const formData = new FormData();
          // formData.append("name", panardName);
          // formData.append("licenseNumber", pancardNo);
          // formData.append("kycType", kycmode);
          // formData.append("drivingFront", drivingLicenFrontfile);
          // formData.append("drivingBack", drivingLicenBackfile);
          let formdata = {
            name: panardName,
            licenseNumber: pancardNo,
            kycType: kycmode,
            drivingFront: drivingLicenFrontfile,
            drivingBack: drivingLicenBackfile,
          };
          setLoading(true);
          try {
            const resp = await axios.post(`/api/v1/user-kyc`, formdata);
            if (resp.status === 200) {
              if (resp?.data?.status === true) {
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/");
                console.log("kyc Update", resp);
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            if (error.response.status === 400) {
              var err_400 = error?.response?.data?.message;
              alert(err_400);
              // toast.error(err_400);
            } else if (error.response.status === 401) {
              const err_401 = error?.response?.data?.message;
              alert(err_401);
              // toast.error(err_401);
            } else if (error.response.status === 500) {
              const err_500 = error?.response?.data?.message;
              alert(err_500);
              // toast.error(err_500);
            } else {
              console.log("Error in Register", error);
              alert("error in DL");
              // toast.error("something went wrong");
            }
          }
          setLoading(false);
        } else {
          toast.error("Upload Images", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Enter Driving License Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Enter Driving License Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("aadharFront", newFile);
            const resp = await axios.post(`api/v1/uploadDocs`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.data[0];
              setFile(uploadedImg);
              if (resp?.data?.status === true) {
                setImagePreviewUrl(reader.result);
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
            // toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
            // toast.error(err_401);
          } else if (error.response.status === 413) {
            const err_413 = "image size should be less then 1 MB";
            alert(err_413);
            // toast.error(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            // toast.error(err_500);
            alert(err_500);
          } else {
            alert("error in kyc details");
            console.log("Error in kyc details", error);
            // toast.error("something went wrong");
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImagePreviewUrl(null);
    }
  };

  const handleAdharcardBack = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("aadharFront", newFile);
            const resp = await axios.post(`api/v1/uploadDocs`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.data[0];
              setAdharbackfile(uploadedImg);
              if (resp?.data?.status === true) {
                setImageAdharBackUrl(reader.result);
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
            // toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
            // toast.error(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            // toast.error(err_500);
            alert(err_500);
          } else {
            alert("error in kyc details");
            console.log("Error in kyc details", error);
            // toast.error("something went wrong");
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setImageAdharBackUrl(null);
    }
  };

  const drivingLicenFront = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("aadharFront", newFile);
            const resp = await axios.post(`api/v1/uploadDocs`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.data[0];
              setDrivingLicenFrontfile(uploadedImg);
              if (resp?.data?.status === true) {
                setDrivingLicenFrontUrl(reader.result);
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
            // toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
            // toast.error(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            // toast.error(err_500);
            alert(err_500);
          } else {
            alert("error in kyc details");
            console.log("Error in kyc details", error);
            // toast.error("something went wrong");
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setDrivingLicenFrontUrl(null);
    }
  };
  const drivingLicenBack = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    if (newFile !== undefined) {
      reader.onloadend = async () => {
        setLoading(true);
        try {
          if (newFile.size > 1024 * 1024) {
            alert("Image size should be less then 1 Mb");
          } else {
            const formdata = new FormData();
            formdata.append("aadharFront", newFile);
            const resp = await axios.post(`api/v1/uploadDocs`, formdata);
            if (resp.status === 200) {
              const uploadedImg = resp.data.data[0];
              setDrivingLicenBackfile(uploadedImg);
              if (resp?.data?.status === true) {
                setDrivingLicenBackUrl(reader.result);
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            alert(err_400);
            // toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            alert(err_401);
            // toast.error(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            // toast.error(err_500);
            alert(err_500);
          } else {
            alert("error in kyc details");
            console.log("Error in kyc details", error);
            // toast.error("something went wrong");
          }
        }
        setLoading(false);
      };
      reader.readAsDataURL(newFile);
    } else {
      setDrivingLicenBackUrl(null);
    }
  };

  let $imagePreview = null;
  if (imagePreviewUrl) {
    $imagePreview = <img src={imagePreviewUrl} alt="Preview" />;
  } else {
    $imagePreview = (
      <div className="previewText">Please select an Image for Preview</div>
    );
  }
  let $adharBackimagePreview = null;
  if (imageAdharBackUrl) {
    $adharBackimagePreview = <img src={imageAdharBackUrl} alt="Preview" />;
  } else {
    $adharBackimagePreview = (
      <div className="previewText">Please select an Image for Preview</div>
    );
  }

  // pan card
  let $drivingLicenBack = null;
  if (drivingLicenBackUrl) {
    $drivingLicenBack = <img src={drivingLicenBackUrl} alt="Preview" />;
  } else {
    $drivingLicenBack = (
      <div className="previewText">Please select an Image for Preview</div>
    );
  }
  let $drivingLicenFront = null;

  if (drivingLicenFrontUrl) {
    $drivingLicenFront = <img src={drivingLicenFrontUrl} alt="Preview" />;
  } else {
    $drivingLicenFront = (
      <div className="previewText">Please select an Image for Preview</div>
    );
  }
  const handlekycStatus = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/v1/user-kycStatus`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          const status = resp?.data?.data?.approvalStatus;
          console.log("data", status);
          setBannerStatus(status);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const handleDrivingLicenseKycReject = async () => {
    if (panardName) {
      if (pancardNo) {
        if (drivingLicenFrontfile && drivingLicenBackfile) {
          let formdata = {
            name: panardName,
            licenseNumber: pancardNo,
            kycType: kycmode,
            drivingFront: drivingLicenFrontfile,
            drivingBack: drivingLicenBackfile,
          };
          setLoading(true);
          try {
            const resp = await axios.put(`/api/v1/resend-user-kyc`, formdata);
            if (resp.status === 200) {
              if (resp?.data?.status === true) {
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/");
                console.log("kyc Update", resp);
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            if (error.response.status === 400) {
              var err_400 = error?.response?.data?.message;
              alert(err_400);
              // toast.error(err_400);
            } else if (error.response.status === 401) {
              const err_401 = error?.response?.data?.message;
              alert(err_401);
              // toast.error(err_401);
            } else if (error.response.status === 500) {
              const err_500 = error?.response?.data?.message;
              alert(err_500);
              // toast.error(err_500);
            } else {
              console.log("Error in Register", error);
              alert("error in DL");
              // toast.error("something went wrong");
            }
          }
          setLoading(false);
        } else {
          toast.error("Upload Images", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Enter Driving License Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Enter Driving License Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  const handleAdharcardKycReject = async () => {
    if (adharcardName) {
      if (adharcardNo) {
        if (file && adharbackfile) {
          let formdata = {
            name: adharcardName,
            aadharNumber: adharcardNo,
            kycType: kycmode,
            aadharFront: file,
            aadharBack: adharbackfile,
          };
          setLoading(true);
          try {
            const resp = await axios.put(`/api/v1/resend-user-kyc`, formdata);
            if (resp.status === 200) {
              if (resp?.data?.status === true) {
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/");
                console.log("kyc Update", resp);
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            if (error.response.status === 400) {
              var err_400 = error?.response?.data?.message;
              alert(err_400);
              // toast.error(err_400);
            } else if (error.response.status === 401) {
              const err_401 = error?.response?.data?.message;
              alert(err_401);
              // toast.error(err_401);
            } else if (error.response.status === 500) {
              const err_500 = error?.response?.data?.message;
              // toast.error(err_500);
              alert(err_500);
            } else {
              alert("error in kyc details");
              console.log("Error in kyc details", error);
              // toast.error("something went wrong");
            }
          }
          setLoading(false);
        } else {
          toast.error("Upload Images", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Enter Adharcard Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Enter Adharcard Name", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  useEffect(() => {
    handlekycStatus();
  }, []);
  return (
    <div>
      <Layout>
        {loading && <Loading loading={loading} />}
        <div className="container">
          <div className="row">
            {bannerStatus === "approved" && (
              <div className="col-12">
                <div className="card align-items-center text-center mt-3">
                  <div className="mt-3">
                    <h4>KYC Status</h4>
                  </div>
                  <div className="img-fluid mt-2" style={{ width: "100px" }}>
                    <img
                      style={{ borderRadius: "50px" }}
                      src="https://img.freepik.com/premium-vector/business-global-economy_24877-41082.jpg"
                      alt="user"
                    />
                  </div>
                  <div
                    className="my-3"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      width: "150px",
                      borderRadius: "15px",
                      paddingTop: "4px",
                    }}
                  >
                    <h5>KYC Success</h5>
                  </div>
                </div>
              </div>
            )}
            {bannerStatus === "pending" && (
              <div className="col-12">
                <div className="card align-items-center text-center mt-3">
                  <div className="mt-3">
                    <h4>KYC Status</h4>
                  </div>
                  <div className="img-fluid mt-2" style={{ width: "100px" }}>
                    <img
                      style={{ borderRadius: "50px" }}
                      src="https://img.freepik.com/premium-vector/business-global-economy_24877-41082.jpg"
                      alt="user"
                    />
                  </div>
                  <div
                    className="my-3"
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      width: "150px",
                      borderRadius: "15px",
                      paddingTop: "4px",
                    }}
                  >
                    <h5>KYC Pending</h5>
                  </div>
                </div>
              </div>
            )}
            {bannerStatus === "rejected"  && bannerStatus !== null &&(
              <div className="col-12">
                <div className="card align-items-center text-center mt-3">
                  <div className="mt-3">
                    <h4>KYC Status</h4>
                  </div>
                  <div className="img-fluid mt-2" style={{ width: "100px" }}>
                    <img
                      style={{ borderRadius: "50px" }}
                      src="https://img.freepik.com/premium-vector/business-global-economy_24877-41082.jpg"
                      alt="user"
                    />
                  </div>
                  <div
                    className="my-3"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      width: "150px",
                      borderRadius: "15px",
                      paddingTop: "4px",
                    }}
                  >
                    <h5>KYC Rejected</h5>
                  </div>
                </div>
              </div>
            )}
            {bannerStatus === null || bannerStatus === "rejected" ?(
              <div className="col-12">
                <div className="card kyc-card">
                  <div className="kyc-header">
                    <h2>kyc form</h2>
                  </div>
                  <div className="card-body kyc-cardbody">
                    <h4>Account Information</h4>
                    <p>
                      Kindly upload your Adhard card or Driving Licence
                      information
                    </p>
                    <div className="row my-3">
                      <div className="col-12 col-md-4 col-lg-4">
                        <div>
                          <label htmlFor="staticEmail" className="form-label">
                            Select your document type
                          </label>
                          <select
                            className="form-select"
                            value={kycmode}
                            onChange={(e) => setkycMode(e.target.value)}
                          >
                            <option value="" disabled>
                              ----Select Asset Mode----
                            </option>
                            <option selected value="aadhar">
                              AdharCard
                            </option>
                            <option value="drivingLicense">
                              Driving License
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {kycmode === "aadhar" && (
                      <div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div>
                              <label
                                htmlFor="staticEmail"
                                className="form-label"
                              >
                                AdharCard Name
                              </label>
                              <input
                                type="text"
                                placeholder="Adhar name"
                                className="form-control"
                                value={adharcardName}
                                onChange={(e) =>
                                  setAdharcardName(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div>
                              <label
                                htmlFor="staticEmail"
                                className="form-label"
                              >
                                AdharCard Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="0000 0000  0000"
                                value={adharcardNo}
                                onChange={(e) => setAdharcardNo(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col-12 col-md-6 my-2">
                            <div className="previewComponent">
                              <label htmlFor="" className="form-label">
                                AdharCard Frontside <span>*</span>
                              </label>
                              {/* <form> */}
                              <input
                                className="fileInput m-0"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                onChange={(e) => handleImageChange(e)}
                              />
                              <span className="text-warning my-3">
                                Image size should be less than 1Mb
                              </span>
                              {/* </form> */}
                            </div>
                          </div>
                          <div className="col-12 col-md-5 my-2">
                            <div className="imgPreview">{$imagePreview}</div>
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col-12 col-md-6 my-2">
                            <div className="previewComponent">
                              <label htmlFor="" className="form-label">
                                AdharCard Backside <span>*</span>
                              </label>
                              <input
                                className="fileInput m-0"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                onChange={(e) => handleAdharcardBack(e)}
                              />
                            </div>
                            <span className="text-warning my-3">
                              Image size should be less than 1Mb
                            </span>
                          </div>

                          <div className="col-12 col-md-5 my-2">
                            <div className="imgPreview">
                              {$adharBackimagePreview}
                            </div>
                          </div>
                        </div>
                        <div className=" col-12 my-3">
                          <button
                            className=" btn cus-btn px-5"
                            onClick={bannerStatus === null ?() => handleAdharcardKyc():() => handleAdharcardKycReject()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}

                    {kycmode === "drivingLicense" && (
                      <div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div>
                              <label
                                htmlFor="staticEmail"
                                className="form-label"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                placeholder="Driving License Name"
                                className="form-control"
                                value={panardName}
                                onChange={(e) => setPancardName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div>
                              <label
                                htmlFor="staticEmail"
                                className="form-label"
                              >
                                Driving License Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="0000 0000  0000"
                                value={pancardNo}
                                onChange={(e) => setPancardNo(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col-12 col-md-6 my-2">
                            <div className="previewComponent">
                              <label htmlFor="" className="form-label">
                                DrivingLicense Frontside <span>*</span>
                              </label>
                              {/* <form> */}
                              <input
                                className="fileInput m-0"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                onChange={(e) => drivingLicenFront(e)}
                              />
                              {/* </form> */}
                            </div>
                          </div>
                          <div className="col-12 col-md-5 my-2">
                            <div className="imgPreview">
                              {$drivingLicenFront}
                            </div>
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col-12 col-md-6 my-2">
                            <div className="previewComponent">
                              <label htmlFor="" className="form-label">
                                DrivingLicense Backside <span>*</span>
                              </label>
                              <input
                                className="fileInput m-0"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                onChange={(e) => drivingLicenBack(e)}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-5 my-2">
                            <div className="imgPreview">
                              {$drivingLicenBack}
                            </div>
                          </div>
                        </div>
                        <div className=" col-12 my-3">
                          <button
                            className=" btn cus-btn px-5"
                            onClick={bannerStatus === null ? () => handleDrivingLicenseKyc() : () => handleDrivingLicenseKycReject()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ):null}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Content;
