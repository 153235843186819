import {
  LOGIN_REQUEST,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  RESET_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
} from "../states/loginState";

const model = {
  request: false,
  isLogged: false,
  userdata: null,
  token: null,
  error: false,
  message: null,
};

const LoginReducer = (state = model, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        request: true,
        isLogged: false,
        userdata: null,
        token: null,
        error: false,
        message: "",
      };

    case LOGIN_SUCCESS:
      return {
        request: false,
        isLogged: true,
        userdata: action.payload.data,
        token: action.payload.token,
        error: false,
        message: action.payload.message,
      };

    case LOGIN_FAILED:
      return {
        request: false,
        isLogged: false,
        userdata: null,
        token: null,
        error: true,
        message: action.payload,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        request: true,
      };
    case LOGOUT_SUCCESS:
      return {
        request: false,
        isLogged: false,
        userdata: null,
        token: null,
        error: false,
        message: action.payload,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        request: false,
        message: action.payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: false,
        message: "",
      };

    default:
      return state;
  }
};

export default LoginReducer;
