import React from "react";
import Layout from "../../sections/Layout/Content";
import { CSVLink, CSVDownload } from "react-csv";

const Content = () => {
  const dummyData = [
    {
      userId: 10,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 1,
      status: "Active",
      country: "India",
      account: "Commissions",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 11,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 2,
      status: "Active",
      country: "India",
      account: "Futures Margin",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 12,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 3,
      status: "Active",
      country: "India",
      account: "TRX",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 13,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 4,
      status: "Active",
      country: "India",
      account: "ETH",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 14,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 5,
      status: "Active",
      country: "India",
      account: "BTC",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 15,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 6,
      status: "Active",
      country: "India",
      account: "USDT",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 16,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 7,
      status: "Active",
      country: "India",
      account: "AVAX",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 17,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 8,
      status: "Active",
      country: "India",
      account: "DOGE",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 18,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 9,
      status: "Active",
      country: "India",
      account: "LTC",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 19,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 10,
      status: "Active",
      country: "India",
      account: "AXS",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
    {
      userId: 20,
      sponsorId: 25,
      totalTrade: "10.0",
      tradingDate: "Aug 27 2023 11:06PM",
      joiningDate: "Aug 26 2023 11:06PM",
      level: 11,
      status: "Active",
      country: "India",
      account: "UNI",
      bal: 0,
      availBal: 0,
      frozen: 0,
    },
  ];
  return (
    <Layout>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center bg-info rounded my-3 p-1">
          <h2 className="text-white">MY TEAM</h2>
        </div>
        <div className="bg-light bg-gradient p-3 rounded">
          <div className="row">
            <div className="col-md-6 col-12 my-2">
              <label className="my-2">From Date</label>
              <input type="date" className="form-control " />
            </div>
            <div className="col-md-6 col-12 my-2">
              <label className="my-2" htmlFor="">
                To Date
              </label>
              <input type="date" className="form-control " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <label className="my-2" htmlFor="">
                Level
              </label>
              <input type="text" className="form-control " />
            </div>
          </div>
          <div className="d-flex align-center justify-content-center my-3">
            <button className="btn btn-info text-white">
              <strong>View Downline</strong>{" "}
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end rounded my-3 p-1">
          <CSVLink className="download" data={dummyData}>
            <button className="btn btn-info text-white mx-2">
              <strong>Export to Excel</strong>{" "}
            </button>
          </CSVLink>

          {/* <CSVDownload data={dummyData} target="_blank" /> */}
          {/* <button className="btn btn-info text-white mx-2">
            <strong>Export to PDF</strong>{" "}
          </button> */}
        </div>
        <div
          className="table-responsive overflow-auto mb-5"
          style={{ height: "300px" }}
        >
          <table className="table">
            <thead>
              <tr className="bg-info text-white">
                <th scope="col">Sr No</th>
                <th scope="col">User ID</th>
                <th scope="col">Sponsor ID</th>
                <th scope="col">Total Trade</th>
                <th scope="col">Date of Joining</th>
                <th scope="col">Trading Date</th>
                <th scope="col">Level</th>
                <th scope="col">Status</th>
                <th scope="col">Country</th>
              </tr>
            </thead>
            <tbody>
              {dummyData.map((data, index) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{data.userId}</td>
                    <td>{data.sponsorId}</td>
                    <td>{data.totalTrade}</td>
                    <td>{data.joiningDate}</td>
                    <td>{data.tradingDate}</td>
                    <td>{data.level}</td>
                    <td>{data.status}</td>
                    <td>{data.country}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
