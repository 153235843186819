import React, { useEffect, useState } from "react";
import Layout from "../../sections/Layout/Content";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./styles.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import banner1 from "../../../image/banner1.png";
import banner2 from "../../../image/banner2.png";
import banner3 from "../../../image/banner3.png";
import banner4 from "../../../image/banner4.png";
const Content = () => {
  const navigate = useNavigate();
  const { LoginReducer } = useSelector((res) => res);
  const [coinList, setCoinList] = useState([]);
  const handleCoinList = async () => {
    // try {
    //   const { data } = await axios.get(
    //     "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false"
    //   );
    //   setCoinList(data);
    //   console.log("data", data);
    // } catch (error) {
    //   console.log("Error in coinList", error);
    // }
    // setLoading(true);
    try {
      const resp = await axios.get(`/api/coin/getAllCoins`);
      if (resp.status === 200) {
        setCoinList(resp?.data?.Coins[0]?.coins);
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    // setLoading(false);
  };

  //  owl js

  const TestiMonialsDetails = ({ testiMonialDetail }) => {
    const { img } = testiMonialDetail;
    console.log("testiMonialDetail" + testiMonialDetail);
    return (
      <div class="item">
        <div class="shadow-effect">
          <img class="img-circle" src={img} />
        </div>
      </div>
    );
  };
  const testiMonials = [
    {
      img: banner1,
    },
    {
      img: banner2,
    },
    {
      img: banner3,
    },
    {
      img: banner4,
    },
  ];
  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 10,
    autoplay: true,
    dots: true,
    autoplayTimeout: 2000,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  // end
  useEffect(() => {
    handleCoinList();
  }, []);

  console.log("coinList", coinList);

  return (
    <>
      <Layout>
        {/* bottomhomepage menu */}
        <div className="container-fluid d-md-none d-block">
          <div className="row">
            <div className="col-12">
              <div className="menunavbox">
                <div className="navlinbtn" onClick={() => navigate("/")}>
                  <div className="iconnavbtn">
                    <i class="fa-solid fa-house"></i>
                  </div>
                  <p>Home</p>
                </div>
                {/* 2 */}
                <div
                  className="navlinbtn"
                  onClick={() => navigate("/market-price")}
                >
                  <div className="iconnavbtn">
                    <i class="fa-solid fa-chart-line"></i>
                  </div>
                  <p>Exchange</p>
                </div>
                {/* 3 */}
                {LoginReducer?.isLogged ? (
                  <div
                    className="navlinbtn"
                    onClick={() => navigate("/deposit")}
                  >
                    <div className="iconnavbtn">
                      <i class="fa-solid fa-money-bill-transfer"></i>
                    </div>

                    <p>Depostit</p>
                  </div>
                ) : (
                  <div
                    className="navlinbtn"
                    onClick={() => toast.error("Please Login and Register")}
                  >
                    <div className="iconnavbtn">
                      <i class="fa-solid fa-money-bill-transfer"></i>
                    </div>
                    <p>Depostit</p>
                  </div>
                )}

                {/* 4 */}
                {LoginReducer?.isLogged ? (
                  <div
                    className="navlinbtn"
                    onClick={() => navigate("/withdraw")}
                  >
                    <div className="iconnavbtn">
                      <i class="fa-solid fa-hand-holding-dollar"></i>
                    </div>
                    <p>Withdraw</p>
                  </div>
                ) : (
                  <div
                    className="navlinbtn"
                    onClick={() => toast.error("Please Login and Register")}
                  >
                    <div className="iconnavbtn">
                      <i class="fa-solid fa-hand-holding-dollar"></i>
                    </div>
                    <p>Withdraw</p>
                  </div>
                )}

                {/* 5 */}
                {LoginReducer?.isLogged ? (
                  <div
                    className="navlinbtn"
                    onClick={() => navigate("/balance")}
                  >
                    <div className="iconnavbtn">
                      <i class="fa-solid fa-circle-dollar-to-slot"></i>
                    </div>
                    <p>Assets</p>
                  </div>
                ) : (
                  <div
                    className="navlinbtn"
                    onClick={() => toast.error("Please Login and Register")}
                  >
                    <div className="iconnavbtn">
                      <i class="fa-solid fa-circle-dollar-to-slot"></i>
                    </div>
                    <p>Assets</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* bottomhomepage menu end */}

        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12 mx-auto d-md-block d-none">
              <div className="bottommenu">
                <div className="row my-md-3  justify-content-evenly">
                  <div className="col-2">
                    <div className="menubtn-box">
                      <button
                        className="btn cus-log-btn  mb-2"
                        onClick={() => navigate("/")}
                      >
                        <i class="fa-solid fa-house"></i>
                        Home
                      </button>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="menubtn-box">
                      {LoginReducer?.isLogged ? (
                        <button
                          className="btn cus-log-btn  mb-2"
                          onClick={() => navigate("/deposit")}
                        >
                          <i class="fa-solid fa-money-bill-transfer"></i>
                          Deposit
                        </button>
                      ) : (
                        <button
                          className="btn cus-log-btn  mb-2"
                          onClick={() =>
                            toast.error("Please Login and Register")
                          }
                        >
                          <i class="fa-solid fa-money-bill-transfer"></i>
                          Deposit
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="menubtn-box">
                      {LoginReducer?.isLogged ? (
                        <button
                          className="btn cus-log-btn  mb-2"
                          onClick={() => navigate("/withdraw")}
                        >
                          <i class="fa-solid fa-hand-holding-dollar"></i>
                          Withdraw
                        </button>
                      ) : (
                        <button
                          className="btn cus-log-btn  mb-2"
                          onClick={() =>
                            toast.error("Please Login and Register")
                          }
                        >
                          <i class="fa-solid fa-hand-holding-dollar"></i>
                          Withdraw
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="menubtn-box">
                      <button
                        className="btn cus-log-btn  mb-2"
                        onClick={() => navigate("/market-price")}
                      >
                        <i class="fa-solid fa-chart-line"></i>
                        Exchange
                      </button>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="menubtn-box">
                      {LoginReducer?.isLogged ? (
                        <button
                          className="btn cus-log-btn mb-2"
                          onClick={() => navigate("/balance")}
                        >
                          <i class="fa-solid fa-circle-dollar-to-slot"></i>
                          Assets
                        </button>
                      ) : (
                        <button
                          className="btn cus-log-btn  mb-2"
                          onClick={() =>
                            toast.error("Please Login and Register")
                          }
                        >
                          <i class="fa-solid fa-circle-dollar-to-slot"></i>
                          Assets
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* owl slider */}
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                id="customer-testimonoals"
                className="owl-carousel owl-theme"
                {...options}
              >
                {testiMonials.length === 0 ? (
                  <div class="item">
                    <div class="shadow-effect">
                      <img class="img-circle" src="../HyperXalphalogo 1.png" />

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna.
                      </p>
                    </div>
                    <div class="testimonial-name">
                      <h5>Rajon Rony</h5>
                      <small>ITALY</small>
                    </div>
                  </div>
                ) : (
                  testiMonials.map((testiMonialDetail) => {
                    return (
                      <TestiMonialsDetails
                        testiMonialDetail={testiMonialDetail}
                        key={testiMonialDetail._key}
                      />
                    );
                  })
                )}
              </OwlCarousel>
            </div>
          </div>
          {/* price card */}
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="card price-card">
                <div className="caption-box">
                  <div className="row align-items-center">
                    <div className="col-6 text-center">
                      <div className="price-logo">
                        <img src={coinList[0]?.image} alt="" />
                        <p>{coinList[0]?.symbol.toUpperCase()}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="price-logo-p">
                        <p>{coinList[0]?.current_price}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ovralay"></div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-6 col-md-3">
              <div className="card price-cardtwo">
                <div className="caption-box">
                  <div className="row align-items-center">
                    <div className="col-6 text-center">
                      <div className="price-logo">
                        <img src={coinList[1]?.image} alt="" />
                        <p>{coinList[1]?.symbol.toUpperCase()}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="price-logo-p">
                        <p> {coinList[1]?.current_price}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ovralay"></div>
              </div>
            </div>

            {/* 3 */}
            <div className="col-6 col-md-3">
              <div className="card price-cardthree">
                <div className="caption-box">
                  <div className="row align-items-center">
                    <div className="col-6 text-center">
                      <div className="price-logo">
                        <img src={coinList[10]?.image} alt="" />
                        <p>{coinList[10]?.symbol.toUpperCase()}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="price-logo-p">
                        <p> {coinList[10]?.current_price}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ovralay"></div>
              </div>
            </div>
            {/* 4 */}

            <div className="col-6 col-md-3">
              <div className="card price-cardfour">
                <div className="caption-box">
                  <div className="row align-items-center">
                    <div className="col-6 text-center">
                      <div className="price-logo">
                        <img src={coinList[11]?.image} alt="" />
                        <p>{coinList[11]?.symbol.toUpperCase()}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="price-logo-p">
                        <p> {coinList[11]?.current_price}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ovralay"></div>
              </div>
            </div>
          </div>
          {/* price card end */}

          <div className="row my-4">
            <div className="col-6">
              <div className="heading-box">
                <h4>Markets</h4>
              </div>
            </div>
            <div className="col-6 col-md-2 ms-auto">
              <div className="search-box">
                <input
                  type="text"
                  placeholder="Search coin"
                  className="form-control shadow-none"
                />
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-8 col-12 mx-md-auto">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {/* <th className="th-padding">#</th> */}
                      <th className="th-padding">Pair</th>
                      <th className="th-padding">Price</th>
                      {/* <th className="th-padding">24H High</th>
                            <th className="th-padding">24H Low</th> */}
                      <th className="th-padding">24H Volume</th>
                      {/* <th>Buy</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {coinList &&
                      coinList.map((value, key) => {
                        return (
                          <tr
                            // onClick={() => navigate(`/market-price/id?${key}`)}
                            onClick={() => (navigate(`/market-price`), localStorage.setItem("coinId",key))}
                          >
                            {/* <th
                                    scope="row"
                                    key={value.id}
                                    className="th-padding"
                                  >
                                    <i class="fa-regular fa-star"></i> {key + 1}
                                  </th> */}

                            <td className="tbl-logo td-padding">
                              <img src={value?.image} alt="" />
                              <span className="spanfont">
                                {value.symbol.toUpperCase()}
                              </span>
                            </td>
                            <td className="td-padding">
                              ${value.current_price}
                            </td>
                            {/* <td className="td-padding">
                                    {value.high_24h}
                                  </td>
                                  <td className="td-padding">
                                    {value.low_24h}
                                  </td> */}
                            <td className="td-padding">
                              ${value.total_volume}
                            </td>
                            {/* <td>
                                    <button
                                      className="btn cus-log-btn"
                                      onClick={() => navigate("/market-price")}
                                    >
                                      Buy
                                    </button>
                                  </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Content;
