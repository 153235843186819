import React, { useState, useEffect } from "react";
import Layout from "../Layout/Content";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const [tradeHistoryData, setTradeHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleHistory = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/v1/user-tradeHistory`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setTradeHistoryData(resp?.data?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleHistory();
  }, []);
  console.log("tradeHistoryData", tradeHistoryData);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="container justify-content-start ">
        <div>
          <h3 className="my-2">Trade History</h3>
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                {/* <th scope="col">userId</th> */}
                <th scope="col">Amount(Buy/Sell)</th>
                <th scope="col">Pair</th>
                <th scope="col">Rate</th>
                <th scope="col">TotalAmount</th>
                <th scope="col">Fees</th>
                {/* <th scope="col">coinId</th>
                <th scope="col">targetCoinName</th> */}
                <th scope="col">Type</th>
                <th scope="col">Date (Buy/Sell)</th>
                <th scope="col">Status</th>
              </tr>
            </thead>

            <tbody>
              {tradeHistoryData.map((data, index) => {
                return (
                  <tr>
                    {/* <th scope="row">{data.userId}</th> */}
                    <td
                      className={
                        data.type == 1 ? "text-danger" : "text-success"
                      }
                    >
                      {data.amount}
                    </td>
                    <td>{data.cryptoPair}</td>
                    <td>{data.rateOfCrypto}</td>
                    <td>{data.totalAllotedAmount}</td>
                    <td>{data.fees}</td>
                    {/* <td>{data.coinId}</td>
                    <td>{data.targetCoinName}</td> */}
                    <td
                      className={
                        data.type == 1 ? "text-danger" : "text-success"
                      }
                    >
                      {data.type == 1 ? "Sell" : "Buy"}
                    </td>
                    <td>{data.buyDate || data.sellDate}</td>
                    <td>{data.tradeStatus}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
