import * as Yup from "yup";
export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "This field must have at least 3 character.")
    .max(25)
    .required("This field is required."),

  lastName: Yup.string()
    .min(3, "This field must have at least 3 character.")
    .max(25)
    .required("This field is required."),
  // referral: Yup.string()
  //   .min(2, "This field must have at least 3 character.")
  //   .max(10)
  //   .required("This field is required."),
  otp: Yup.string()
    .min(6, "This field must have at least 6 character.")
    .max(6, "This field must have at least 6 character.")
    .required("This field is required."),
  email: Yup.string()
    .email("Invalid email")
    .required(" This field is required. "),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("This field is required."),
  confirmPassword: Yup.string()
    .required(" This field is required. ")
    .oneOf([Yup.ref("password"), null], "password must match"),
});
