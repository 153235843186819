import React, { useState } from "react";
import DepositQr from "../../../image/DepositQr.jpg";
import { useFormik } from "formik";
import { AdminDepositSchema } from "./AdminDepositSchema";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AdminDeposit = () => {
  const navigate = useNavigate();
  const [copyLink, setCopyLink] = useState("copy");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState("");

  const initialValues = {
    // userAddress: "",
    amount: "",
  };
  const handleCopyLink = () => {
    let textToCopy = "0xE3C4fd1217318BfcCa1964AaDFf10fFA384800f3"; // The text you want to copy
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyLink("Copied!");
        setTimeout(() => {
          setCopyLink("Copy");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: AdminDepositSchema,
      onSubmit: async (values) => {
        if (file) {
          const formData = new FormData();
          formData.append("accountAddress", "0x11");
          formData.append("depositAmount", values.amount);
          formData.append("DepositeScreenShot", file);
          setLoading(true);
          try {
            const resp = await axios.post(`/api/v1/depositRequest`, formData);
            if (resp.status === 200) {
              if (resp?.data?.status === true) {
                toast.success(resp?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/");
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            if (error.response.status === 400) {
              var err_400 = error?.response?.data?.message;
              alert(err_400);
              // toast.error(err_400);
            } else if (error.response.status === 401) {
              const err_401 = error?.response?.data?.message;
              alert(err_401);
              // toast.error(err_401);
            } else if (error.response.status === 500) {
              const err_500 = error?.response?.data?.message;
              // toast.error(err_500);
              alert(err_500);
            } else {
              alert("error in kyc details");
              console.log("Error in kyc details", error);
              // toast.error("something went wrong");
            }
          }
          setLoading(false);
        } else {
          toast.error("Please upload screenshort", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      },
    });

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    console.log(reader, newFile);
    if (newFile !== undefined) {
      reader.onloadend = () => {
        setFile(newFile);
        setImagePreviewUrl(reader.result);
      };

      reader.readAsDataURL(newFile);
    } else {
      setImagePreviewUrl(null);
    }
  };
  let $imagePreview = null;
  if (imagePreviewUrl) {
    $imagePreview = <img src={imagePreviewUrl} alt="Preview" />;
  } else {
    $imagePreview = (
      <div className="previewText">Please select an Image for Preview</div>
    );
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="deposit-box">
          <h3>Deposit</h3>

          <div className="despisit-box">
            <div className="imgdeposit">
              <img src={DepositQr} alt="" />
            </div>

            <div className="input-group  my-3">
              <input
                type="text"
                className="form-control cus-font-r"
                placeholder="0xE3C4fd1217318BfcCa1964AaDFf10fFA384800f3"
                aria-label="Username"
                aria-describedby="basic-addon1"
                disabled
                readonly
              />
              <div className="input-group-prepend" onClick={handleCopyLink}>
                <span
                  className="input-group-text cus-btn-copy"
                  id="basic-addon1"
                >
                  {copyLink}
                </span>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            {/* <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1" className="mb-1">
                sendAddress
                <span className="text-danger mx-1 fw-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control cus-formcontrol"
                placeholder="Enter Address"
                name="userAddress"
                value={values.userAddress}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.userAddress && touched.userAddress ? (
                <div className="form-text text-danger">
                  {errors.userAddress}
                </div>
              ) : null}
            </div> */}
            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1" className="mb-1">
                Amount
                <span className="text-danger mx-1 fw-bold">*</span>
              </label>
              <input
                type="number"
                className="form-control cus-formcontrol"
                placeholder="Enter USDT Amount"
                name="amount"
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.amount && touched.amount ? (
                <div className="form-text text-danger">{errors.amount}</div>
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1" className="mb-1">
                Screenshot
                <span className="text-danger mx-1 fw-bold">*</span>
              </label>
              <input type="file" onChange={(e) => handleImageChange(e)} />
              <div>
                <span className="text-warning my-3">
                  Image size should be less than 1Mb
                </span>
              </div>

              <div className="col-12 col-md-12 my-2">
                <div className="imgPreview">{$imagePreview}</div>
              </div>
            </div>
            <button type="submit" className="btn form-btn w-100">
              Deposit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminDeposit;
