import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Layout from "../../sections/Layout/Content";
import { WithdrawSchema } from "./WithdrawSchema";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [usdtBalance, setUsdtBalance] = useState("");
  const [isOtpShow, setisOtpShow] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [otpExpiration, setOtpExpiration] = useState(180); // 5 minutes in seconds
  const [showOtpfield, setShowOtpFiels] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpVarify, setIsVerify] = useState(false);
  const [withdraw, setwithdraw] = useState(false);
  const initialValues = {
    withdrawAddress: "",
    assetMode: "",
    amount: "",
  };
  const handleVerify = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(`/api/v1/verifyWithdrawalEmail`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setShowOtpFiels(true);
          setisOtpShow(true)
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);

    setTimeout(() => {
      setIsVerify(true);
    }, 3000);
    console.log("otp", otp);
  };
  const handleResendOtp = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(`/api/v1/resendWithdrawalOTP`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setOtpExpired(false)
          setOtpExpiration(180)
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: WithdrawSchema,
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const data = {
            withdrawalAmount: values.amount,
            address: values.withdrawAddress,
          };
          const resp = await axios.post(`/api/v1/user-withdrawal`, data);
          if (resp.status === 200) {
            if (resp?.data?.status === true) {
              toast.success(resp?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/");
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            toast.error("Error", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in Register", error);
            toast.error("something went wrong");
          }
        }
        setLoading(false);
      },
    });
  const handlecheckOtp = async () => {
    try {
      const otpapiResponse = await axios({
        method: "POST",
        url: "/api/v1/verifyWithdrawalOTP",
        data: {
          otp,
        },
      });
      const outputOtpApi = otpapiResponse?.data;
      if (outputOtpApi?.status) {
        setwithdraw(true);
        toast.success(outputOtpApi?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setwithdraw(false);
        toast.error(outputOtpApi?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log("Otp Response", outputOtpApi);
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
        setwithdraw(false);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
        setwithdraw(false);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  // opt expired
  useEffect(() => {
    const TimeCount = async () => {
      var countdownInterval = "";
      if (isOtpShow) {
        countdownInterval = setInterval(() => {
          setOtpExpiration((prevExpiration) => prevExpiration - 1);
        }, 1000);
      }
      return () => {
        clearInterval(countdownInterval);
      };
    };
    TimeCount();
  }, [isOtpShow]);

  useEffect(() => {
    if (otpExpiration <= 0) {
      setOtpExpired(true);
    }
    if (otpExpiration === 0) {
      setShowResend(true);
    }
  }, [otpExpiration]);

  useEffect(() => {
    if (otp?.toString().length == 6) {
      // alert("call OTP");
      handlecheckOtp();
    }
  }, [otp]);

  // Format the countdown time as "mm:ss"
  const formattedTime = `${String(Math.floor(otpExpiration / 60)).padStart(
    2,
    "0"
  )} : ${String(otpExpiration % 60).padStart(2, "0")}`;

  useEffect(() => {
    const handleUsdtBalance = async () => {
      setLoading(true);
      try {
        const resp = await axios.get(`/api/v1/user-wallet-assets`);
        if (resp.status === 200) {
          if (resp?.data?.status === true) {
            setUsdtBalance(resp?.data?.data[0]?.balance);
          } else {
            toast.error("Error", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          var err_400 = error?.response?.data?.message;
          toast.error(err_400);
        } else if (error.response.status === 401) {
          const err_401 = error?.response?.data?.message;
          toast.error(err_401);
        } else if (error.response.status === 500) {
          const err_500 = error?.response?.data?.message;
          toast.error(err_500);
        } else {
          console.log("Error in Register", error);
          toast.error("something went wrong");
        }
      }
      setLoading(false);
    };
    handleUsdtBalance();
  }, []);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <section className="depositbg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="deposit-box">
                <h3>Withdraw Assets</h3>
                {/* <form onSubmit={handleSubmit}> */}
                <div className="form-group mb-3">
                  <label htmlFor="exampleInputEmail1" className="mb-1">
                    Wallet Address
                    <span className="text-danger mx-1 fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control cus-formcontrol"
                    placeholder="Enter Wallet Address"
                    name="withdrawAddress"
                    value={values.withdrawAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.withdrawAddress && touched.withdrawAddress ? (
                    <div className="form-text text-danger">
                      {errors.withdrawAddress}
                    </div>
                  ) : null}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="exampleInputPassword1" className="mb-1">
                    Asset Mode
                    <span className="text-danger mx-1 fw-bold">*</span>
                  </label>
                  <select
                    className="form-control cus-formcontrol"
                    name="assetMode"
                    value={values.assetMode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled>
                      ----Select Asset Mode----
                    </option>
                    <option value="USDT">USDT</option>
                  </select>
                </div>
                {errors.assetMode && touched.assetMode ? (
                  <div className="form-text text-danger">
                    {errors.assetMode}
                  </div>
                ) : null}
                <div className="form-group mb-3">
                  <label htmlFor="exampleInputEmail1" className="mb-1">
                    Withdrawal Amount
                    <span className="text-danger mx-1 fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control cus-formcontrol"
                    placeholder="Withdrawal Amount"
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.amount && touched.amount ? (
                  <div className="form-text text-danger">{errors.amount}</div>
                ) : null}
             { !withdraw && <div>
               {showOtpfield && (
                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputEmail1" className="mb-1">
                      OTP
                      <span className="text-danger mx-1 fw-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control cus-formcontrol"
                      placeholder="Enter Otp"
                      name="FinalAmount"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <div>
                      {otpExpired ? (
                        <p className="my-2">OTP has expired.</p>
                      ) : (
                        <p>OTP will expire in {formattedTime}</p>
                      )}
                    </div>
                  </div>
                )}
                {showResend && (
                  <div className="form-group mb-3">
                    <button
                      className="btn form-btn"
                      onClick={() => handleResendOtp()}
                      disabled={!otpExpired}
                    >
                      Resend OTP
                    </button>
                  </div>
                )}
               </div>}
                <div className="col-12 my-3">
                  <div className="row">
                    <div className="d-flex align-items-center">
                      <div className="col-9">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="mb-1 fw-bold"
                          >
                            Total Balance : {usdtBalance} USDT
                          </label>
                        </div>
                      </div>
                      <div className="col-3">
                        <button
                          className="btn form-btn w-100"
                          onClick={() =>
                            (values.amount = usdtBalance.toString())
                          }
                        >
                          Max
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {isOtpVarify ? (
                  <button
                    className="btn form-btn w-100"
                    onClick={() => handleSubmit()}
                    disabled={!withdraw}
                  >
                    Withdraw
                  </button>
                ) : (
                  <button
                    className="btn form-btn w-100"
                    onClick={() => handleVerify()}
                  >
                    Verify Email
                  </button>
                )}

                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Content;
