import { createStore, applyMiddleware, combineReducers } from "redux";

import logger from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoginReducer from "./reducers/loginReducer";
const middleware = applyMiddleware(logger, thunk);

const rootReducer = combineReducers({
  LoginReducer: LoginReducer,
});

const initialState = {};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, initialState, middleware);
export const persistor = persistStore(store);
