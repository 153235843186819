import React, { useEffect, useState } from "react";
import "./styles.css";
import Layout from "../Layout/Content";
import axios from "axios";
import { toast } from "react-toastify";
const Content = () => {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const handleHistoryData = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/v1/userWithdrawalDetails`);
      console.log(resp)
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setHistoryData(resp?.data?.userWithdrawalList);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };

  useEffect(() => {
    handleHistoryData();
  }, []);

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 my-3">
            <div className="card-header data-header">
              <p>Withdraw History</p>
            </div>
            <div className="card data-card cus-data-card p-2">
              {/* data */}

              <div className="row">
                <div className="col-12">
                  {historyData &&
                    historyData.map((value, key) => {
                      return (
                        <>
                          <div className="card py-3 m-4 within-data table-responsive">
                            <table className="table table-borderless table-data">
                              <tbody>
                                <tr>
                                  <th>UserId</th>
                                  <td>:</td>
                                  <td>{value.userId}</td>
                                </tr>
                                <tr>
                                  <th>
                                    {value.withdrawalAmount
                                      ? "WithdrawalAmount"
                                      : "DepositAmount"}
                                  </th>
                                  <td>:</td>
                                  <td
                                    className={
                                      value.withdrawalAmount
                                        ? "text-danger fw-bold"
                                        : "text-success fw-bold"
                                    }
                                  >
                                    {value.withdrawalAmount
                                      ? value.withdrawalAmount
                                      : value.depositAmount}
                                  </td>
                                </tr>
                                {value?.WithdrawalAmount && (
                                  <tr>
                                    <th>Commission</th>
                                    <td>:</td>
                                    <td>{value?.commission}</td>
                                  </tr>
                                )}
                                {value?.WithdrawalAmount && (
                                  <tr>
                                    <th>TotalWithdrawalAmount</th>
                                    <td>:</td>
                                    <td>{value?.totalWithdrawalAmount}</td>
                                  </tr>
                                )}
                                <tr>
                                  <th>Address</th>
                                  <td>:</td>
                                  <td>{value.accountAddress || "-"}</td>
                                </tr>
                                {value.WithdrawalAmount && (
                                  <tr>
                                    <th>Transaction Hash</th>
                                    <td>:</td>
                                    <td>{value?.transactionHash}</td>
                                  </tr>
                                )}
                                <tr>
                                  <th>Date </th>
                                  <td>:</td>
                                  <td>
                                    {value.withdrawalRequestDate
                                      ? convertTimestamp(value.withdrawalRequestDate)
                                      : convertTimestamp(value.depositDate)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Approval </th>
                                  <td>:</td>
                                  <td className={value.Approval === 'pending' ?`text-warning`:value.Approval === 'approved' ?`text-success`: `text-danger` }>
                                    {value.Approval}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
