import React from "react";
import { Routes, Route } from "react-router-dom";
import MarketPage from "./components/pages/MarketPage";
import LandingPage from "./components/pages/LandingPage";
import LoginPage from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/RegisterPage";
import ForgotPage from "./components/pages/ForgotPage";
import MarketPricePage from "./components/pages/MarketPricePage";
import DepositePage from "./components/pages/DepositPage";
import WithdrawPage from "./components/pages/WithdrawPage";
import ErrorPage from "./components/pages/ErrorPage";
import ForgotPasswordEmail from "./components/sections/ForgotPageSection/ForgotPasswordEmail";
import BalancePage from "./components/pages/BalancePage";
import ReferralPage from "./components/pages/ReferralPage";
import UserPage from "./components/pages/UserPage";
import { store } from "./components/sections/Redux/store";
import axios from "axios";
import TeamPage from "./components/pages/TeamPage";
import TradeHistoryPage from "./components/pages/TradeHistoryPage";
import KycPage from "./components/pages/KycPage";
import NewsPage from "./components/pages/NewsPage";
import HistoryPage from "./components/pages/HistoryPage";
import WithdrawPendingPage from "./components/pages/WithdrawPendingPage";
// import HistoryPage from "./components/pages/HistoryPage";
function App() {
  (function () {
    const state = store.getState();
    if (state.LoginReducer.isLogged) {
      const token = state.LoginReducer.token;
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("auth")}`;
      }
    }
  })();
  return (
    <Routes>
      <Route path="/" element={<MarketPage />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register/" element={<RegisterPage />} />
      <Route path="/register/:id" element={<RegisterPage />} />
      <Route path="/forgot/:id" element={<ForgotPage />} />
      <Route path="/forgot/email" element={<ForgotPasswordEmail />} />
      <Route path="/market-price" element={<MarketPricePage />} />
      <Route path="/market-price/:id" element={<MarketPricePage />} />
      <Route path="/deposit" element={<DepositePage />} />
      <Route path="/withdraw" element={<WithdrawPage />} />
      <Route path="/balance" element={<BalancePage />} />
      <Route path="/referrals" element={<ReferralPage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/user" element={<UserPage />} />
      <Route path="/trade-history" element={<TradeHistoryPage />} />
      <Route path="/kyc" element={<KycPage />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/history" element={<HistoryPage />} />
      <Route path="/withdrawal-pending" element={<WithdrawPendingPage />} />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
