import * as Yup from "yup";
export const LoginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required(" This field is required. "),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("This field is required."),
});
