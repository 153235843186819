import { createChart, ColorType, TickMarkType } from "lightweight-charts";
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
const ChartComponent = (props) => {
  const {
    data,
    colors: {
      backgroundColor = "#222",
      lineColor = "#444",
      textColor = "#fff",
      areaTopColor = "#444",
      areaBottomColor = "rgba(41, 98, 255, 0.28)",
    } = {},
    setSelectedValue,
  } = props;
  const [candlePrice, setCandlePrice] = useState();
  const coinName = localStorage.getItem("coinName");
  const chartContainerRef = useRef();
  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: backgroundColor },
        textColor,
      },
      grid: {
        vertLines: {
          color: "#444",
        },
        horzLines: {
          color: "#444",
        },
      },
      width: chartContainerRef.current.clientWidth,
      height: 350,
    });
    chart.timeScale().applyOptions({
      borderColor: "#71649C",
      rightOffset: 10,
      timeVisible: true,
      barSpacing: 15,
      minBarSpacing: 10,
      fixLeftEdge: true,
      tickMarkFormatter: (time, tickMarkType, locale) => {
        const date = new Date(time * 1000);
        switch (tickMarkType) {
          case TickMarkType.Year:
            return date.getFullYear();

          case TickMarkType.Month:
            const monthFormatter = new Intl.DateTimeFormat(locale, {
              month: "short",
            });
            return monthFormatter.format(date);
          case TickMarkType.DayOfMonth:
            return date.getDate();

          case TickMarkType.Time:
            const timeFormatter = new Intl.DateTimeFormat(locale, {
              hour: "numeric",
              minute: "numeric",
            });
            return timeFormatter.format(date);
        }
      },
    });
    chart.timeScale().fitContent();

    const newSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });
    const lineSeries = chart.addLineSeries();
    newSeries.setData(data);
    chart.subscribeCrosshairMove((param) => {
      if (param.time) {
        const data = param.seriesData.get(newSeries);
        setCandlePrice(data);
      }
    });
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);

  return (
    <div ref={chartContainerRef} style={{ position: "relative" }}>
      <div className="haverContain p-0">
        <div style={{ display: "block" }}>
          <div style={{ marginRight: 10, color: "#fff" }}>
            Open:{candlePrice?.open}
          </div>
          <div style={{ marginRight: 10, color: "green" }}>
            High:{candlePrice?.high}
          </div>
          <div style={{ marginRight: 10, color: "red" }}>
            Low:{candlePrice?.low}
          </div>
          <div style={{ marginRight: 10, color: "#fff" }}>
            Close:{candlePrice?.close}
          </div>
        </div>
      </div>
      <div>
      {/* Dropdown Select  */}
      {/* {coinName === "HyperXAlpha" || coinName === "HyperCoin" ? (
          <select
            className="form-select cus-select"
            value={localStorage.getItem("adminCoinTime")}
            onChange={(e) => (setSelectedValue(e.target.value), localStorage.setItem("adminCoinTime", e.target.value))}
          >
            <option selected>Select</option>
            <option value={1}>1 min</option>
            <option value={5}>5 min</option>
            <option value={15}>15 min</option>
            <option value={1440}> 1 day</option>
            <option value={2880}>2 day</option>
            <option value={21600}>15 day</option>
            <option value={43200}>30 day</option>
          </select>
        ) : (
          <select
            className="form-select cus-select"
            value={localStorage.getItem("coinTime")}
            onChange={(e) => (setSelectedValue(e.target.value), localStorage.setItem("coinTime", e.target.value))}
          >
            <option selected>Select</option>
            <option value={"1"}>1 Day</option>
            <option value={"7"}>7 Day</option>
            <option value={"14"}>15 Day</option>
            <option value={"30"}>1 Month</option>
            <option value={"90"}>3 Month</option>
            <option value={"180"}>6 Month</option>
            <option value={"365"}>1 Year</option>
            <option value={"max"}>Max</option>
          </select>
        )} */}
      </div>
    </div>
  );
};

export default ChartComponent;
