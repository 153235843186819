import React, { useEffect } from "react";
import "../LoginPageSection/styles.css";
import Layout from "../../sections/Layout/Content";
import { NavLink, Link } from "react-router-dom";
import { useFormik } from "formik";
import { LoginSchema } from "./LoginSchema";
import LoginUser from "../Redux/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_ERROR } from "../Redux/states/loginState";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Content = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: (value) => {
        dispatch(LoginUser(value));
      },
    });
  const { LoginReducer } = useSelector((res) => res);
  useEffect(() => {
    if (LoginReducer.message !== "") {
      if (LoginReducer.error) {
        toast.error(LoginReducer.message);
        dispatch({ type: RESET_ERROR });
      } else {
        if (LoginReducer.isLogged) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${LoginReducer.token}`;
          toast.success(LoginReducer.message);
          dispatch({ type: RESET_ERROR });
          navigate("/");
        }
      }
    }
  }, [LoginReducer.error, LoginReducer.message]);
  return (
    <>
      <Layout>
        <section className="logbg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="logbox">
                  <h3>Login</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group mb-3">
                        <label htmlFor="exampleInputEmail1" className="mb-1">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control cus-formcontrol"
                          placeholder="Enter email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <div className="form-text text-danger">
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="exampleInputPassword1" className="mb-1">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control cus-formcontrol"
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password ? (
                          <div className="form-text text-danger">
                            {errors.password}
                          </div>
                        ) : null}
                      </div>

                      <button type="submit" className="btn form-btn w-100">
                        Login
                      </button>
                    </div>
                  </form>
                  <div className="row my-3">
                    <div className="col-6">
                      <div className="linkbox">
                        <NavLink to="/forgot/email">Forgot Password?</NavLink>
                      </div>
                    </div>
                    <div className="col-6 text-end">
                      <div className="linkbox">
                        <NavLink to="/register">Register an account</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Content;
