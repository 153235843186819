import React, { useEffect, useState } from "react";
import Layout from "../../sections/Layout/Content";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../LoaderPageSection/Loading";

const Content = () => {
  const [balanceData, setBalanceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCallBalance = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/api/v1/user-wallet-assets`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setBalanceData(resp?.data?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleCallBalance();
  }, []);
  console.log("CoinData", balanceData);
  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="container justify-content-start ">
        <div>
          <h3 className="my-2">My Assets</h3>
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Account</th>
                <th scope="col">Balance</th>
                <th scope="col">Available Balance</th>
                {/* <th scope="col">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {balanceData.map((data, index) => {
                return (
                  <tr>
                    <th scope="row">{data.symbol}</th>
                    <td>{data.balance}</td>
                    <td>{data.balance}</td>

                    {/* <td>
                      <button className="btn btn-primary mx-1 rounded-pill">
                        Deposit
                      </button>
                      <button className="btn btn-outline-primary mx-1 rounded-pill">
                        Withdraw
                      </button>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
