import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./components/sections/Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
// axios.defaults.baseURL = "http://localhost:5000";
// axios.defaults.baseURL = "http://192.168.1.37:4000";
// axios.defaults.baseURL = "http://192.168.1.51:4000";
axios.defaults.baseURL = "https://devbackend.hyperxalpha.com";
// axios.defaults.baseURL = "https://backend.hyperxalpha.com";
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
        <ToastContainer />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

reportWebVitals();
