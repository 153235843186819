import React from "react";
import Layout from "../../sections/Layout/Content";
const Content = () => {
  return (
    <Layout>
      <div>LandingPage</div>
    </Layout>
  );
};

export default Content;
